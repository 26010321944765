// components/InteractiveResumeBuilder.tsx
import React, { useEffect, useRef, useState } from 'react';
import fetchOpenAIResponse from '../../services/openai.service';
import './chatbot.scss';
import { CardContent, IconButton, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import { FaRobot, FaSearch } from 'react-icons/fa';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import ReactMarkdown from 'react-markdown';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  answerCard: {
    marginTop: useTheme().spacing(2),
    backgroundColor: '#f5f5f5', // Light grey background for the card
    animation: '$bounceIn 0.5s', // Apply the animation

  },
  answerTitle: {
    marginBottom: useTheme().spacing(1), // Adds space below the title
  },
  scrollableAnswer: {
    position: 'relative',
    maxHeight: '400px',
    overflowY: 'auto',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '30px',
      backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))',
      transition: 'opacity 0.3s',
    },
  },
  noFade: {
    '&::after': {
      opacity: 0,
    },
  },
  '@keyframes bounceIn': {
    '0%': {
      transform: 'scale(0.5)',
      opacity: 0,
    },
    '60%': {
      transform: 'scale(1.05)',
      opacity: 1,
    },
    '80%': {
      transform: 'scale(0.95)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
}));

const ChatBot: React.FC = () => {
  const classes = useStyles();
  const [isAtBottom, setIsAtBottom] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const checkScroll = () => {
      if (!scrollRef.current) return;
      const isBottom = scrollRef.current.scrollHeight - scrollRef.current.scrollTop === scrollRef.current.clientHeight;
      setIsAtBottom(isBottom);
    };

    const scrollableElement = scrollRef.current;
    scrollableElement?.addEventListener('scroll', checkScroll);

    return () => scrollableElement?.removeEventListener('scroll', checkScroll);
  }, []);

  const [question, setQuestion] = useState<string>('');
  const [answer, setAnswer] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setLoading(true);
    setAnswer(null);
    const response = await fetchOpenAIResponse(question);
    setAnswer(response || null);
    setLoading(false);
  };

  return (
    <div className="interactive-resume-builder">
      <div className="interactive-resume-builder-container">
      {/* Label "AI Faris" with robot icon */}
      <Typography variant="h6" component="div" style={{ marginRight: '16px' }}>
        <FaRobot style={{ marginRight: '4px' }} /> <b>Ai</b> Faris
      </Typography>
      {/* Form */}
      <form onSubmit={handleSubmit} style={{ flex: 1 }}>
        <TextField
          className='chatbot-input'
          label="Ask me anything about my resume..."
          variant="outlined"
          fullWidth
          value={question}
          onChange={handleQuestionChange}
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSubmit} disabled={loading}>
                  <FaSearch />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            style: { color: 'black' },
          }}
        />
      </form>
    </div>
      {loading ? (<CircularProgress style={{color: 'black'}}/>) : (answer && (
        <Card className={classes.answerCard}>
          <CardContent>
            <Typography variant="h6" component="h3" className={classes.answerTitle}>
            <FaRobot /> <b>Ai</b> Faris' Response:
            </Typography>
            <Typography variant="body1" className={classes.scrollableAnswer}>
            <div style={{ textAlign: 'left' }} ref={scrollRef} className={`${classes.scrollableAnswer} ${isAtBottom ? classes.noFade : ''}`}>
      {/* Content that may overflow and requires scrolling */}
      {answer && <ReactMarkdown className='answer'>{answer}</ReactMarkdown>}
    </div>
            </Typography>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ChatBot;
