import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from "./layout/layout.component";
import AboutMe from "./pages/aboutme/aboutme.page";
import Cert from "./pages/cert/cert.page";
import Experience from "./pages/experience/experience.page";
import Home from "./pages/home/home.page";
import NotFound from "./pages/notfound/notfound.page";
import Skills from "./pages/skills/skills.page";
import "./pageTransitions/slideTransition.scss";
import React from "react";
import { Container, createTheme } from '@mui/material'
import "./App.scss"
import ParallaxSection from "./components/parallax/parallax-section.component";
import { Parallax } from "react-parallax";
import Portfolio from "./components/portfolio/portfolio.component";
import HomeContainer from './pages/home-container/home-container.component';

const navRoutes: any = [
  {
    routeName: '/',
    component: <HomeContainer />
  },
  {
    routeName: '/aboutme',
    component: <AboutMe />
  },
  {
    routeName: '/experience',
    component: <Experience />
  },
  {
    routeName: 'portfolio',
    component: <Portfolio />
  }
]

const App = () => {

  
  const renderRoute = () => {
    return navRoutes.map((x: any) => {
      return <Route key={x.routeName} path={x.routeName} element={x.component} />
    })
  }

  return (
    <Layout>
      <Routes>
        {renderRoute()}
      </Routes>
    </Layout>
  );
};

export default App;
