// services/resumeService.ts
import resumeData from '../data/resumeContent.json';
import { Experience, Client, Project } from './resume.model';


export const formatResumeContent = () => {
  const experienceFormatted = resumeData.experience.map((job: Experience) => {
    const responsibilitiesFormatted = job.responsibilities.map((responsibility: string) => {
      return `- ${responsibility}`;
    }).join('\n');
    return `
    ${job.title} at ${job.company}
    ${job.location}
    ${job.dates}
    Responsibilities:
    ${responsibilitiesFormatted
  }).join('\n\n')`;
});

const clientFormatted = resumeData.clients.map((client: Client) => {
  const projectsFormatted = client.projects.map((project: Project) => {
    const responsibilitiesFormatted = project.responsibilities.map((responsibility: string) => {
      return `- ${responsibility}`;
    }).join('\n');
    return `
    ${project.name}
    ${project.description}
    Responsibilities:
    ${responsibilitiesFormatted
  }).join('\n\n')`})
  return `
    ${client.company}
    Projects:
    ${projectsFormatted}
  `
});

  const skillsFormatted = resumeData.skills.backendDevelopment.join(', ')
  .concat(resumeData.skills.frontendDevelopment.join(', '))
  .concat(resumeData.skills.infraIntegrations.join(', '));
  



  return `
  ${resumeData.name}
  ${resumeData.contact.email}

  Experience:
  ${experienceFormatted}

  Skills:
  ${skillsFormatted}

  Education:
  ${resumeData.education}

  Certifications:
  ${resumeData.certifications}

  Projects/Portfolio/Clients:
  ${clientFormatted}
  `;
}