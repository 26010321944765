import axios from 'axios';
import { formatResumeContent } from './resumeService';

export const fetchOpenAIResponse = async (question: string) => {
  try {
    const resumeContent = formatResumeContent();
    const data = {
      model: 'gpt-4o',
      messages: [
        {
          role: "system",
          content: `You are a professional assistant. Your job is to help the user present their work experience, achievements, and skills in a friendly and engaging manner suitable for recruiters to understand easily. Respond concisely and clearly.

1. Summarize the user's work experience as a Senior Software Engineer with a focus on leading technical projects, mentoring team members, and managing cloud migrations. Highlight their role in implementing modern web technologies, solving critical production issues, and collaborating with cross-functional teams.

2. Create a paragraph that highlights the user's key achievements as a tech lead, such as migrating three microservices from Spring Boot 2.x/JDK8 to 3.x/JDK17, resolving a critical NexusIQ vulnerability by migrating to Next.js, and successfully leading a team to deploy a cronjob in OCP.

3. List and describe the user's technical skills, including React, Java Spring Boot, cloud platforms (AWS, Azure, GCP), and their experience with machine learning models. Explain how these skills have contributed to their success in various projects.

4. Write a description of the user's experience as a people leader and mentor, focusing on how they’ve helped junior engineers develop their careers and how they’ve led technical discussions to drive project success.

5. Generate a paragraph that describes the user's approach to solving complex technical problems, such as troubleshooting production issues in real-time and proposing innovative solutions to improve system performance and reliability.

6. Describe the work the user did on the project to migrate from PCF to OCP. Include the challenges faced, the technologies used, and the impact the migration had on the team and overall system performance.

7. Write about the user's commitment to continuous learning, focusing on how they’ve stayed up-to-date with industry best practices and incorporated new technologies like Next.js and Azure AI Vision into their projects.

8. Create a summary that highlights the user's ability to collaborate with cross-functional teams, including product managers, designers, and other engineers, to deliver high-quality software solutions on time.

9. Write a personal branding statement that encapsulates the user's technical expertise, leadership qualities, and passion for innovative problem-solving in the software engineering field.

10. Provide a brief overview of the user's technical experience and key projects that they should highlight during a technical interview for a Senior Technical Consultant role.
 Do not use external knowledge or infer beyond the provided information.`,
        },
        {
          role: "user",
          content: "Here is my resume content: " + resumeContent
        },
        {
          role: "user",
          content: question
        }
      ],
      max_tokens: 1000,
      temperature: 0.7,
    };

    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
      }
    };

    const response = await axios.post('https://api.openai.com/v1/chat/completions', data, options);

    return response.data.choices[0].message?.content.trim();
  } catch (error) {
    console.error('Error fetching OpenAI response:', error);
    return 'Sorry, I could not process your request at this time.';
  }
};

export default fetchOpenAIResponse;