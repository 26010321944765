import { useEffect } from "react";
import ParallaxSection from "../../components/parallax/parallax-section.component";
import AboutMe from "../aboutme/aboutme.page";
import Experience from "../experience/experience.page";
import Home from "../home/home.page";
import { useLocation } from "react-router-dom";
import ChatBot from "../../components/chatbot/chatbot.component";
import { Grid } from "@mui/material";
import './home-container.scss';
import SkillSet from "../../components/skillsets/skillsets.component";

const HomeContainer = () => {
    const location = useLocation();

    useEffect(() => {
      // Function to scroll to an element by ID
      const scrollToElement = (id:any) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
  
      const hashId = location.hash.replace('#', '');

      // If there's a hash in the URL, scroll to the element with that ID
      if (hashId) {
        scrollToElement(hashId);
      }
    }, [location]); // Re-run this effect if the location changes
  
    const container = () => {
        return (
            <div className="">

                <ParallaxSection classes="gradient-background helloworldgif" imageSrc="" strength={700}>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12}>
                        <div>
                            <ChatBot />
                        </div>
                    </Grid>
                </Grid>
                </ParallaxSection>
                <div className="overlay-divider"></div>
                <ParallaxSection imageSrc="/white-ruins.png" strength={700}>
                <Home />
                    <AboutMe />
                </ParallaxSection>
                {/* <ParallaxSection imageSrc="" strength={700}>
                    <SkillSet />
                </ParallaxSection> */}
                <ParallaxSection classes="gradient-background" imageSrc="" strength={500}>
                    <Experience />
                </ParallaxSection>

            </div>
        )
    }
    return (
        <>
            {container()}
        </>
    );
}

export default HomeContainer;
