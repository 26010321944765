import CircleImage from "../../components/circle-image/circle-image.component"
import './home.scss'

import { makeStyles } from '@mui/styles';
import { Tooltip, Typography, useTheme } from "@mui/material"
import classnames from "classnames"
import { FaApple, FaDiscord, FaGithub, FaLinkedin, FaMusic } from "react-icons/fa"


const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    marginBottom: 50,
    width: '90%',
    maxWidth: 1280,
    padding: 50,
    [useTheme().breakpoints.down('sm')]: {
      width: '85%',
    },
    textAlign: 'center',
  },
  picture: {
    margin: 'auto',
    width: 100,
    borderRadius: 100,
  },
  text: {
    color: 'black',
  },
  title: {
    fontFamily: '"Nunito", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: useTheme().breakpoints.down('md') ? '3.5rem' : '5.90rem',
    marginTop: '30px !important',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: '1.5rem',
  },
  icons: { 
    marginTop: 32,
  },
  icon: {
    // width: 40,
    fill: '#000',
    padding: '0 8px',
  },
}));

const Icon = ({ component, tooltip, analyticsEvent, ...props } : any) => (
  <Tooltip title={tooltip} enterTouchDelay={0}>
    <a
      target='_blank'
      rel='noopener'
      className={`umami--click--${analyticsEvent}-social-hero`}
      {...props}
    >
      {component}
    </a>
  </Tooltip>
);


const Home = () => {
  const classes = useStyles();

    return (
      <div id="home" className={classes.root}>
        <div className={classes.container}>
        <CircleImage className={classes.picture} src="/profile-pic.jpg" />
          <Typography variant='h2' className={classnames(classes.text, classes.title)}>
            Faris Shatat
          </Typography>
          <Typography variant='h5' className={classnames(classes.text, classes.subtitle)}>
            Engineer, Thinker &amp; Producer
          </Typography>
          <div className={classes.icons}>
            <Icon
              tooltip='frshatat'
              component={<FaGithub className={`iconStyle ${classes.icon}`} />}
              href='https://github.com/frshatat'
              analyticsEvent='github'
            />
            <Icon
              tooltip='farisshatat'
              component={<FaLinkedin className={`iconStyle ${classes.icon}`}/>}
              href='https://www.linkedin.com/in/farisshatat'
              analyticsEvent='linkedin'
            />
            <Icon
              tooltip='music'
              component={<FaMusic className={`iconStyle ${classes.icon}`} />}
              href='https://hyperfollow.com/tripio'
              analyticsEvent='music'
            />
          </div>
        </div>
      </div>
    );
}

export default Home