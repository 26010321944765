import { Typography } from '@mui/material';
import { FaAward, FaBusinessTime, FaCode, FaUserGraduate } from 'react-icons/fa';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import data from '../../data/education.json';
import { Icon } from '../icon/icon.component';
import './vertical-timeline.scss';
import ParallaxSection from '../parallax/parallax-section.component';

interface Props {
  items: {
    date: string;
    title: string;
    description: string;
    icon: string;
    image: string;
  }[];
}

const VerticalTimelineCard: React.FunctionComponent<Props> = ( prop : Props) => {
  const iconMapper = (icon: string) => {
    let iconComponent: any;
    switch (icon) {
        case 'FaUserGraduate':
            iconComponent = <FaUserGraduate />;
            break;
        case 'FaAward':
            iconComponent = <FaAward />;
            break;
        case 'FaCode':
            iconComponent = <FaCode/>;
            break;
        case 'FaPeficient':
            iconComponent = <Icon name="peficient" imageUrl='/perficient-logo.jpeg' />;
            break;
        case 'FASlalom':
            iconComponent = <Icon name="slalom" imageUrl='/slalom-logo.png' />;
            break;
        case 'FAKinCarta':
            iconComponent = <Icon name="slalom" imageUrl='/kin+carta-logo.png' />;
            break;
        default:
            iconComponent = <FaBusinessTime />;
    }
    return iconComponent;
  };
  return (
    <VerticalTimeline>
      {prop.items?.map((item: any) => {
        return (
          <VerticalTimelineElement
            key={item.title}
            date={item.date}
            icon={iconMapper(item.icon)}
          >
            <Typography variant='h5' fontWeight={'bold'} className='vertical-timeline-element-title'>
              {item.title}
            </Typography>
            <Typography variant='h6' className='vertical-timeline-element-subtitle'>
              {item.description}
            </Typography>
          </VerticalTimelineElement>

        );
      })}
    </VerticalTimeline>

  );
};

export default VerticalTimelineCard;