import { Timeline } from "@mui/lab"
import { Box, Typography, Container, useTheme } from "@mui/material"
import { makeStyles } from '@mui/styles';
import { ActionAreaCard } from "../../components/action-card/action-card.component"
import CircleImage from "../../components/circle-image/circle-image.component"
import Header from "../../components/header/header"
import Meta from "../../components/meta/meta"
import SocialMediaIcons from "../../components/socialmedia-icons/socialmedia-icon.component"
import TitleText from "../../components/title-text/title-text.component"
import './aboutme.scss'


const useStyles = makeStyles((theme: any) => ({
  section: {
    paddingTop: 24,
    paddingBottom: 24,
    boxShadow: useTheme().shadows[2],
  },
  aboutme: {
    fontWeight: 300,
    padding: '1rem 0',
    fontSize: '1.5rem',
  },
  subsectionTitle: {
    textAlign: 'center',
    margin: '20px 0',
    fontWeight: 500,
  },
}));
const AboutMe = () => {

  const description = () => {
    return (
      <>
        <Meta title={pageTitle} />
        <div className="home-page inner-container">
          <div className="middle about-me-description">

            <ActionAreaCard  text="
During my downtime, you'll typically find me immersed in the rhythm, crafting beats that fuse the pulse of hip hop with the electrifying vibes of electronic music. Music isn't just a pastime for me; it's my creative sanctuary, where I can unleash my imagination. When I'm not in the studio, I'm out exploring the wonders of the great outdoors, conquering challenging hikes and reveling in the breathtaking beauty of nature. Traveling is another passion of mine – I thrive on immersing myself in different cultures, from the vibrant streets of Spain to the serene landscapes of Norway and the rich history of Palestine.

But my explorations aren't confined to physical journeys alone; I'm also an avid seeker of knowledge. You'll often find me engrossed in theological treatises, historical epics, and philosophical musings, constantly expanding my intellectual horizons. In my professional role as a Technical Consultant, I've honed my skills by tackling complex challenges and delivering innovative solutions. However, my thirst for learning remains unquenched; I'm always on the lookout for new opportunities to evolve and push the boundaries of what's possible in the dynamic world of technology." imageUrl="/IMG_2346.JPG" />
          </div>
          <h2 />
        </div>
      </>
    )
  }
  const classes = useStyles();

  // page content
  const pageTitle = 'Faris Shatat'
  const pageDescription = 'welcome to react bootstrap template'

  return (
    <div id="aboutMe" className="page parent">
      <Box className={classes.section} id='aboutme'>
        <Container>
        {description()}
        </Container>
      </Box>
    </div>

  )
}

export default AboutMe