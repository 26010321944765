import * as React from 'react';
import './circle-image.scss';

interface CircleImageProps {
  src: string;
  className: string;
  // Other props for your component
}

const CircleImage: React.FC<CircleImageProps> = (props) => {
  return (
    <div className="circle-image">
      <img className={props.className} src={props.src} alt="Circle image" />
    </div>
  );
};

export default CircleImage;