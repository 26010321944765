

// components

import { Parallax } from "react-parallax";
import Footer from "../components/footer/footer.component";
import Header from "../components/menu/menu.comonent";
import Menu from "../components/menu/menu.comonent";
import ParticleAnimation from "../components/particle-animation/particle-animation.component";
import './layout.scss'
import ParallaxSection from "../components/parallax/parallax-section.component";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";



const Layout = ({ children }: any) => {
  const useStyles = makeStyles((theme) => ({
    content: {
    '@media (max-width: 900px)': {
      paddingLeft: 0, // Account for sidebar width
    },
    paddingLeft: 240, // Account for sidebar width
    flexGrow: 1, // Take up remaining space
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    },
  }));
  
  const classes = useStyles();

  return (

    <>
      <Header />
      <main className={classes.content}>
        <div id="top">{children}</div>
      </main>
      <Footer />
    </>

  );
};

export default Layout;