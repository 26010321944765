import React from 'react'
import ReactDOM from 'react-dom'

import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from './helpers/scrollToTop.helper'
import { createMuiTheme, createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import 'core-js/features/object/has-own';
if (!Object.hasOwn) {
  Object.hasOwn = (obj, prop) =>
    Object.prototype.hasOwnProperty.call(obj, prop);
}
const theme = createTheme({

  palette: {
    primary: {
      main: '#fff',
      light: 'rgba(195,195,195,0.11)',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      // default: '#000000',
      // paper: '#000000',
    },
    text: {
      primary: '#000000',
      secondary: '#fff',
    },
    divider: '#ffffff',
  },
  typography: {
    fontFamily: 'Nunito',
  },
});


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <App />

      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()