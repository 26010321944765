import React from 'react';
import Header from "../../components/header/header";
import Meta from "../../components/meta/meta";
import Timeline from "../../components/timeline/timeline.component";
import TitleText from "../../components/title-text/title-text.component";
import VerticalTimelineCard from "../../components/vertical-timeline/vertical-timeline.component";
import data from '../../data/education.json';
import './experiance.scss';

const Experience = () => {
  // page content
  return (
    <div className='page-container page experience' id='experience'>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 time-line-container">
            <div className="title-container">
              <TitleText text="Work History" className="" />
            </div>
            <div className="scrollable-container">
              <VerticalTimelineCard items={data} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Experience;
