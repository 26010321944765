import React, { useEffect, useState } from 'react';
import './portfolio.scss';
import projects from '../../data/projects.json';
interface Project {
  project: string;
  impact: string;
  image: string;
  responsibilitiesAchievements: string[];
  responsibilitiesAchievementsEnhancements?: string[];
  responsibilitiesAchievementsStatePriceTransparency?: string[];
}

const Portfolio: React.FC = () => {

  const projectsOjbect = JSON.parse(JSON.stringify(projects)) as Project[];
  return (
    <div className="portfolio">
      {projectsOjbect.map((project: Project, index: number) => (
        <div key={index} className="project">
          <div className='project-image-container'>
            <img src={project.image} alt={project.project} className="project-image" />
          </div>
          <div className='project-description'>
            <h2 className="project-title">{project.project}</h2>
            <p className="project-impact"><strong>Project Impact:</strong> {project.impact}</p>
            <ul className="responsibilities-achievements">
              {project.responsibilitiesAchievements?.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
            {project.responsibilitiesAchievementsEnhancements && (
              <div>
                <h3>Responsibilities and Achievements (Enhancements):</h3>
                <ul>
                  {project.responsibilitiesAchievementsEnhancements.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              </div>
            )}
            {project.responsibilitiesAchievementsStatePriceTransparency && (
              <div>
                <h3>Responsibilities and Achievements (State Price Transparency):</h3>
                <ul>
                  {project.responsibilitiesAchievementsStatePriceTransparency.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>

        </div>
      ))}
    </div>
  );
};

export default Portfolio;
