import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Link as ScrollLink } from 'react-scroll';
import { NavLink, Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Hidden } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faLightbulb, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import MobileNavigation from './mobileMenu/mobile-menu.component';

const useStyles = makeStyles(() => ({
  sidebar: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    maxWidth: '240px', // Ensure it doesn't get too wide
    background: 'linear-gradient(31deg, rgba(40, 40, 40, 1) 6%, rgba(47, 47, 47, 1) 21%, rgba(53, 53, 53, 1) 46%, rgba(59, 58, 62, 1) 76%, rgba(60, 60, 60, 1) 97%);',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '5vh', // Use viewport height for padding
    zIndex: 1300,
    boxShadow: '11px -20px 19px 10px rgba(0, 0, 0, 0.1);',
    transition: 'width 0.3s', // Smooth transition for width changes
  },
  logo: {
    marginBottom: '5vh',
    textAlign: 'center',
  },
  logoText: {
    fontSize: '2.5rem', // Adjusted font size for better scaling
    fontWeight: 'bold',
    color: '#fff',
    textDecoration: 'none',
    "& > img": {
      width: '80%', // Adjust logo width for responsiveness
    }
  },
  menuItem: {
    width: '100%',
    padding: '1.5vh 0', // Adjust padding for responsiveness
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '1.5rem', // Adjust font size for responsiveness
    color: '#fff',
    textDecoration: 'none',
    transition: 'background-color 250ms',
    '&:hover': {
      backgroundColor: '#737371',
    },
    '& .icon': {
      display: 'block',
      marginBottom: '0.5vh',
      fontSize: '1.5rem', // Adjust icon size for responsiveness
      width: '100%',
    },
  },
  active: {
    backgroundColor: '#737371',
  },
  drawerPaper: {
    width: '90vw', // Use viewport width for responsiveness
    maxWidth: '240px', // Ensure it doesn't get too wide
    background: 'linear-gradient(31deg, rgba(40, 40, 40, 1) 6%, rgba(47, 47, 47, 1) 21%, rgba(53, 53, 53, 1) 46%, rgba(59, 58, 62, 1) 76%, rgba(60, 60, 60, 1) 97%);',
    boxShadow: '11px -20px 19px 10px rgba(0, 0, 0, 0.1);',
    color: '#fff',
  },
  drawerIcon: {
    color: '#fff !important',
  },
  drawerList: {
    paddingTop: '5vh', // Adjust padding for responsiveness
  },
  drawerItem: {
    color: '#fff',
    paddingTop: '2vh !important',
    paddingBottom: '2.5vh !important',
    '&:hover': {
      backgroundColor: '#000',
    },
  },
  drawerItemFirst: {
    color: '#fff',
    paddingTop: '5vh !important',
    paddingBottom: '2.5vh !important',
    '&:hover': {
      backgroundColor: '#000',
    },
  },
  drawerIconButton: {
    top: '2vh',
    left: '2vh',
    zIndex: 1400,
    color: '#fff !important',
    position: 'fixed !important' as any,
    backgroundColor: 'black !important',
    width: '40px !important',
  },
  // Add responsive styles
  '@media (max-width: 768px)': {
    sidebar: {
      width: '90vw',
      maxWidth: '240px',
    },
    menuItem: {
      fontSize: '1.2rem',
      padding: '1vh 0',
    },
    logoText: {
      fontSize: '2rem',
    },
  },
  '@media (max-width: 480px)': {
    sidebar: {
      width: '80vw',
      maxWidth: '240px',
    },
    menuItem: {
      fontSize: '1rem',
      padding: '0.5vh 0',
    },
    logoText: {
      fontSize: '1.5rem',
    },
  },
}));


interface HeaderProps {
  siteTitle: string;
  homepage?: string;
}

const Header = ({ siteTitle, homepage }: HeaderProps) => {
  const classes = useStyles();
  const [activeLink, setActiveLink] = useState<string | null>(null);
  const location = useLocation();

  const menuItems = [
    { text: 'ABOUT', icon: faUserAlt, scrollink: 'aboutme', link:'/#aboutme', type: 'scroll' },
    { text: 'EXPERIENCE', icon: faLightbulb, scrollink: 'experience', link:'/#experience', type: 'scroll' },
    { text: 'PROJECTS', icon: faBriefcase, scrollink: 'portfolio', link: 'portfolio', type: 'route' }
    // Add more items here as needed
  ];
  const isHomePage = location.pathname === '/'; // Check if current page is the home page

  return (
    <>
      <Hidden mdDown>
        <div className={classes.sidebar}>
          <div className={classes.logo}>
            <a href="/" className={classes.logoText}>
              <img src='./fs-logo.png' alt='Faris Shatat' />
            </a>
            <p style={{ fontSize: '1.4rem' }}><b>Consulting</b> People<br /> <b>Building</b>  Software</p>
          </div>
          {menuItems.map((item, index) => (
            item.type === 'scroll' && isHomePage ? (
              <ScrollLink
                key={index}
                href='/'
                activeClass={activeLink === item.link ? classes.active : ''}
                to={item.scrollink}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className={classes.menuItem}
              >
                <FontAwesomeIcon icon={item.icon} className="icon" />
                <span>{item.text}</span>
              </ScrollLink>
            ) : (
              <NavLink
                key={index}
                to={item.link}
                className={({ isActive }) => isActive ? `${classes.menuItem} ${classes.active}` : classes.menuItem}
                onClick={() => setActiveLink(item.link)} // Update the active link state
              >
                <FontAwesomeIcon icon={item.icon} className="icon" />
                <span>{item.text}</span>
              </NavLink>
            )
          ))}
        </div>
      </Hidden>
      <MobileNavigation menuItems={menuItems} styles={classes} />
    </>
  );
};
Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: 'Faris Shatat',
};

export default Header;
