import React, { ReactNode, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Hidden, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, useLocation } from 'react-router-dom';
import { Link } from 'react-scroll';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './mobile-menu.scss';

type MobileNavigationProps = {
  menuItems: {
        text: ReactNode;
        icon: IconProp;
    scrollink: string; // Optional scroll link
    link: string;
    type: string;
  }[];
  styles?: any;
};

const MobileNavigation = ({ menuItems, styles }: MobileNavigationProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const [activeLink, setActiveLink] = useState<string | null>(null);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const classes: any = styles;
  const isHomePage = location.pathname === '/'; // Check if current page is the home page

  return (
    <div className='mobile-menu'>
      <Hidden mdUp>
        <IconButton
          edge="start"
          className={classes.drawerIconButton}
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
        >
          <MenuIcon className={classes.drawerIcon} />
        </IconButton>
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={() => {
            handleDrawerToggle();
          }}
          classes={{ paper: classes.drawerPaper }}
        >
          <div className={classes.logo}>
            <a href="/" className={classes.logoText}>
              <img src="./fs-logo.png" alt="Faris Shatat" />
            </a>
            <p className={classes.logoText} style={{ fontSize: '1.4rem' }}>
              <b>Consulting</b> People
              <br /> <b>Building</b> Software
            </p>
          </div>
          <List className={classes.drawerList}>
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                {item.type === 'scroll' && isHomePage ? (

                  <Link
                    to={item.scrollink}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    onClick={handleDrawerToggle}
                    className={classes.menuItem}
                    activeClass={classes.active}
                    >
                    <ListItem button>
                      <ListItemIcon>
                        <FontAwesomeIcon icon={item.icon} className="icon menu-icon" />
                      </ListItemIcon>
                      <ListItemText className="nav-item" primary={item.text} />
                    </ListItem>
                  </Link>
                ) : (
                    <NavLink
                    key={index}
                    to={item.link}
                    className={({ isActive }) => isActive ? `${classes.menuItem} ${classes.active}` : classes.menuItem}
                    onClick={() => {setActiveLink(item.link); handleDrawerToggle();}} // Update the active link state
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <FontAwesomeIcon icon={item.icon} className="icon menu-icon" />
                      </ListItemIcon>
                      <ListItemText className="nav-item" primary={item.text} />
                    </ListItem>
                  </NavLink>
                )}
              </React.Fragment>
            ))}
          </List>
        </Drawer>
      </Hidden>
    </div>
  );
};

export default MobileNavigation;
