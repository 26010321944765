import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardContent, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

interface Props {
  text: string;
  imageUrl: string;
}





export const ActionAreaCard: React.FC<Props> = ({ text, imageUrl }: any) => {
  const theme = useTheme();

  const useStyles = makeStyles({
    root: {
      backgroundColor: 'white',
      color: 'black'
    },
    primary: {
      color: 'black'
    },
    // [theme.breakpoints.up('md')]: {
    //   bodyText: {
    //     fontSize: '1.9rem !important',
    //   }

    // },
    // [theme.breakpoints.down('md')]: {
    //   bodyText: {
    //     fontSize: '1.3rem !important',
    //   }

    // },


  });
  const classes = useStyles();

  return (
    <Card >
      <CardContent className={classes.root}>
        <Typography gutterBottom variant="h2" component="div">
          About Me
        </Typography>
        <Typography color="text.primary">
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
}