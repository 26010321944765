import * as React from 'react';
import './icon.scss';

interface Props {
  name: string;
  imageUrl?: string;
  className?: string;
}

export const Icon: React.FC<Props> = ({ name, imageUrl, className }) => {
  return (
    <div className={`icon ${className}`}>
      {imageUrl ? (
        <img src={imageUrl} alt={name} className="icon-image" />
      ) : (
        <i className={`icon-glyph ${name}`}></i>
      )}
    </div>
  );
};