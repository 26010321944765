import { useTheme } from '@emotion/react';
import * as React from 'react';
import './title-text.scss';

interface TitleTextProps {
  text: string;
  size?: string;
  className: string;
}

const TitleText: React.FC<TitleTextProps> = (props) => {
  const theme = useTheme();
  return (
    <h1 className={`title-text ${props.className}`} style={{ fontSize: props.size }}>{props.text}</h1>
  );
};

export default TitleText;