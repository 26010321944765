import React, { ReactNode } from 'react';
import { Parallax } from 'react-parallax';
import './parallax-section.scss';

interface Props {
  children: ReactNode;
  imageSrc: string;
  strength?: number;
  classes?: string;
}


const ParallaxSection: React.FC<Props> = ({ children, imageSrc, strength, classes }) => {
  return (
    <Parallax bgImage={imageSrc} strength={strength} className={classes}>
      <div className="parallax-section">
       {children}
      </div>
    </Parallax>
  );
};

export default ParallaxSection;
